import { HashLink } from 'react-router-hash-link';
import { links, socials } from "./data";
import './footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="container footer__container">
        <ul className="nav__menu">
          {links.map(fLink => (
            <li key={fLink.id}>
              <HashLink smooth to={fLink.link}>
                {fLink.title}
              </HashLink>
            </li>
          ))}
        </ul>
        <div className="footer__socials">
          {socials.map(social => (
            <a
              key={social.id}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {social.icon}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
