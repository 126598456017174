import SoccerClub_Thumbnail from "../../assets/kcsc_thumbnail2.png";
import StudyHive_Thumbnail from "../../assets/studyhivepic.png";

const data = [
  {
    id: 1,
    category: "fullstack",
    image: SoccerClub_Thumbnail,
    title: "Kalamazoo College Soccer Club Web App (Fullstack)",
    desc: "A website for students involved in Kalamazoo College's soccer club to stay updated in news related to the club, view club schedule, and view photos taken for the club",
    github: "https://github.com/terrynuechterlein/kSoccerClubWebsite",
    demo: "https://kcollegesoccerclub.com",
  },
  {
    id: 2,
    category: "fullstack",
    image: StudyHive_Thumbnail,
    title: "StudyHive Mobile Application (Fullstack)",
    desc: " A platform to connect students on college campuses with tutors from their campus while facilitating collaboration through shared passion projects",
    github: "https://github.com/terrynuechterlein/TutorAppFrontend",
    blog: "true", //indicates this project has a blog page
  },
];

export default data;
