import React from "react";
import TerryPhoto from "../assets/Terry.png";
import WelcomeImage from "../assets/TutorAppBlogPics/welcome.PNG";
import SignUpImage from "../assets/TutorAppBlogPics/signup.PNG";
import SignInImage from "../assets/TutorAppBlogPics/signin.PNG";
import "./tutorappblog.css";
import ProfileImage from "../assets/TutorAppBlogPics/profile.PNG";
import EditProfileImage from "../assets/TutorAppBlogPics/editprofile.PNG";
import DiscoverImage from "../assets/TutorAppBlogPics/discover.PNG";
import DiscoverFilterImage from "../assets/TutorAppBlogPics/discoverfilter.PNG";
import UserProfileImage from "../assets/TutorAppBlogPics/userprofile.jpg";
import ChatImage from "../assets/TutorAppBlogPics/chat.PNG";
import MessageImage from "../assets/TutorAppBlogPics/message.PNG";
import SettingsImage from "../assets/TutorAppBlogPics/settings.PNG";
import BecomeTutorImage from "../assets/TutorAppBlogPics/becometutor.PNG";
import ServiceImage from "../assets/TutorAppBlogPics/service.PNG";

const TutorAppBlog = () => {
  return (
    <section id="blog" className="blog">
      <div className="blog__container">
        <div className="blog__header">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h1 className="blog__title">
            Building StudyHive: Connecting Students Through Technology
          </h1>
          <br />
          <br />
          <div className="blog__meta">
            <div className="blog__author-photo">
              <img src={TerryPhoto} alt="Terry Nuechterlein" />
            </div>
            <div className="blog__author-info">
              <p>
                By <strong>Terry Nuechterlein</strong>
              </p>
              <p>October 2024</p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="blog__content">
          <h2>The Birth of StudyHive</h2>
          <p>
            {" "}
            StudyHive is a mobile app I am developing using React Native for the
            frontend and ASP.NET Core for the backend. The primary goal of this
            application is to help fight the financial hardships of college life
            and foster collaboration among peers. Every student possesses unique
            strengths and skills, and I wanted to create a platform that
            harnesses that diversity. The inspiration for StudyHive came from my
            own desire to learn beat production in a more personal setting than
            online courses could offer. While on campus, I discovered that many
            students had developed impressive skills through their passions—some
            had even produced beats for mainstream artists. I saw these talented
            individuals as incredible learning resources.
            <br />
            <br />
            StudyHive will provide a space where students can share their
            expertise, learn from one another, and even earn income by offering
            tutoring services and taking advantage of the different skills they
            have. 
          </p>

          <br />
          <br />

          <h2>Getting Started</h2>
          <p>
            The authentication stack consists of three screens: the Welcome
            page, the Sign Up page, and the Sign In page. I took inspiration from
            Duolingos layout and choice of including a Welcome page. When you
            open the app, you're greeted with a simple welcome screen where the
            sign up and login screens are easily accessiblie. The information
            from the sign up form gets securely stored in the User table in the
            database.
          </p>
          <p>
            As an extra layer of security, I used JWT authentication. When you
            log in, the server verifies your credentials in the database and
            generates a JWT. This token is stored on the client side using redux
            and is included in the header of subsequent requests, so you don't
            need to re-authenticate each time.
          </p>
          <p>
            The User table includes fields like <code>Id</code>,{" "}
            <code>UserName</code>, <code>Email</code>, and{" "}
            <code>PasswordHash</code>. I chose to hash passwords, as a
            professional practice to keep the information secure if the database
            were to be attacked.
          </p>

          <div className="image-sequence">
            <img
              src={WelcomeImage}
              alt="Welcome Screen"
              className="sequence-image"
            />
            <span className="sequence-arrow">→</span>
            <img
              src={SignUpImage}
              alt="Sign Up Screen"
              className="sequence-image"
            />
            <span className="sequence-arrow">→</span>
            <img
              src={SignInImage}
              alt="Sign In Screen"
              className="sequence-image"
            />
          </div>

          <br />
          <br />
          <br />

          <h2>Personalizing Your Profile</h2>
          <p>
            After logging in, you can personalize your profile by adding your
            name, school, major, and social media links. This information is
            stored in the User table.
          </p>
          <p>
            To get experience with working with cloud services, I have the
            Profile pictures and banners (and any other types of media in the
            app, like resumes) get uploaded to Azure Blob Storage containers,
            and store the URLs in the database within the User model.
          </p>

          <div className="image-pair">
            <img
              src={ProfileImage}
              alt="Profile Screen"
              className="small-profile-image"
            />
            <img
              src={EditProfileImage}
              alt="Edit Profile Screen"
              className="small-profile-image"
            />
          </div>

          <br />
          <br />
          <br />

          <h2>Discovering and Connecting</h2>
          <p>
            The Discover page lets you search for other students based on
            college, major, or grade. This feature queries the User table using
            filters you select, making networking easy. For example, if you
            filter by "Computer Science" majors at "Kalamazoo College," the app
            constructs a query to retrieve matching users from the database.
          </p>

          <div className="image-pair">
            <img
              src={DiscoverImage}
              alt="Discover Page"
              className="small-profile-image"
            />
            <img
              src={DiscoverFilterImage}
              alt="Filter Selection Modal"
              className="small-profile-image"
            />
          </div>
          <br />
          <br />
          <h2>Messaging</h2>
          <p>
            I've included a messaging system that allows you to chat with other
            users. Messages are stored in the Message table, which is linked to
            the User table through foreign keys. Each message record includes a{" "}
            <code>SenderId</code> and a <code>ReceiverId</code>, referencing the{" "}
            <code>Id</code> in the User table. When you send a message, it's
            saved in the database, and the recipient can retrieve it in
            real-time.
          </p>

          <br />
          <p>
            To access the chat screen, you can view someones profile from the
            Discover page and press the blue message icon. The Chat screen
            handles the real-time messaging between two users. The Messages
            screen works with the Chat screen by fetching a list of all
            conversations involving the current users and displays them as
            cards. When you select a conversation on the Message screen, it
            navigates to the chat screen with the selected user's details. My
            future plan with the messaging system is to make it so where it's
            not possible to message someone else unless you two are friends, to
            improve the privacy of a user so they don't get unwanted messages
            from random people.
          </p>

          <div className="image-sequence">
            <img
              src={UserProfileImage}
              alt="User Profile"
              className="sequence-image"
            />
            <span className="sequence-arrow">→</span>
            <img src={ChatImage} alt="Chat Screen" className="sequence-image" />
            <img
              src={MessageImage}
              alt="Messages Screen"
              className="sequence-image"
            />
          </div>

          <br />
          <br />

          <h2>Collaborating on Projects</h2>
          <p>
            With the Projects feature, you can create or join collaborative
            projects. Projects are stored in the Project table, and memberships
            are managed through the ProjectMember table.
          </p>
          <p>
            The ProjectMember table links users to projects using foreign keys{" "}
            <code>UserId</code> and <code>ProjectId</code>. This many-to-many
            relationship allows multiple users to be part of multiple projects.
          </p>

          <br />
          <br />
          <h2>Becoming a Tutor</h2>
          <p>
            StudyHive allows students to become tutors by verifying their .edu
            email addresses. I implemented this feature using a Spring Boot
            microservice, which handles email verification and uses a PostgreSQL
            database.
          </p>
          <p>
            When you submit your .edu email, the microservice sends a
            verification link to your inbox. Once verified, your tutor status is
            updated in the User table. This ensures only legitimate students can
            offer tutoring services.
          </p>
          <p>
            Tutors can then create service listings stored in the Service table,
            with different tiers saved in the ServiceTier table. Each service is
            linked to the tutor's user ID, establishing a one-to-many
            relationship.
          </p>

          <div className="image-sequence">
            <img
              src={SettingsImage}
              alt="Settings Screen"
              className="sequence-image"
            />
            <span className="sequence-arrow">→</span>
            <img
              src={BecomeTutorImage}
              alt="Become Tutor Screen"
              className="sequence-image"
            />
            <span className="sequence-arrow">→</span>
            <img
              src={ServiceImage}
              alt="Service Creation Screen"
              className="sequence-image"
            />
          </div>

          <br />
          <br />
          <br />

          <h2>Looking Ahead</h2>
          <p>
            Developing StudyHive has been an incredible journey. It deepened my
            understanding of software development, databases, and the impact
            technology can have. There's still a lot of work ahead, and I'm
            excited to continue improving the app, adding new features, and
            enhancing the user experience.
          </p>
          <p>
            There's always more to learn, and I'm eager to see how StudyHive
            evolves!
          </p>
        </div>
      </div>
    </section>
  );
};

export default TutorAppBlog;
