import React from "react";
import Navbar from "./sections/navbar/Navbar";
import Header from "./sections/header/Header";
import WorkExperience from "./sections/work-experience/WorkExperience";
import Portfolio from "./sections/portfolio/Portfolio";
import About from "./sections/about/About";
import Footer from "./sections/footer/Footer";
import Contact from "./sections/contact/Contact";
// import Modal from "./components/Modal";
import Theme from "./theme/Theme";
import {useThemeContext} from "./context/theme-context";
import TutorAppBlog from "./Blogs/TutorAppBlog";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import ScrollToTop from "./ScrollTop";

const App = () => {
  const {themeState} = useThemeContext();

  return (
    <Router>
      <main className={`${themeState.primary} ${themeState.background}`}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <About />
                <WorkExperience />
                <Portfolio />
                <Contact />
              </>
            }
          />
          <Route path="/tutorappblog" element={<TutorAppBlog />} />
        </Routes>
        <Footer />
        <Theme />
      </main>
    </Router>
  );
};

export default App;
