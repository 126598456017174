import TerryLogo from "../../assets/TerryLogo.png";
import data from "./data";
import "./navbar.css";
import {FaPaintBrush} from "react-icons/fa";
import {useModalContext} from "../../context/modal-contex";
import {HashLink} from "react-router-hash-link";

const Navbar = () => {
  const {showModalHandler} = useModalContext();
  return (
    <nav>
      <div className="container nav__container">
        <HashLink smooth to="/#header" className="nav__logo">
          <img src={TerryLogo} alt="TerryLogo" />
        </HashLink>
        <ul className="nav__menu">
          {data.map((item) => (
            <li key={item.id}>
              <HashLink smooth to={item.link}>
                {item.title}
              </HashLink>
            </li>
          ))}
        </ul>
        <button id="theme__icon" onClick={showModalHandler}>
          <FaPaintBrush />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
